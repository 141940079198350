import { Grid } from '$shared/components/Grid';
import styled from '@emotion/styled';
import React, { memo, RefObject, useRef, FC } from 'react';
import { Category, M20CategoriesModule } from '~/lib/data-contract';
import { Gutter, Image, Text, Carousel } from '~/shared/components';
import { weakKey } from '~/shared/utils/jsx';
import { ModuleContainer } from '~/templates/blocks/components/ModuleContainer';
import { ModuleHeader } from '../ModuleHeader';
import ChevronLeft from '$icons/chevron-left.svg';
import type { BaseHit } from 'instantsearch.js';
import ChevronRight from '$icons/chevron.svg';
import {
    Gradient,
    StyledHeading,
    StyledCategories,
    StyledCategory,
    StyledContent,
    StyledText,
    StyledButton,
    StyledFlex,
    StyledLink,
    StyledAlignContent,
} from './styled';
import { breakpointsRaw } from '~/theme/breakpoints';
import { mq } from '~/lib';
import Flex from '~/shared/components/Flex';
import FlexContent from '~/shared/components/FlexGrowContent';
import { usePathname } from 'next/dist/client/components/navigation';
import algoliasearchHelper from 'algoliasearch-helper';

/* Imports below are only utilized when `isSearchShortcuts` is true */
import { useUtils } from '~/shared/hooks/useUtils';
import { useHits } from 'react-instantsearch';
import { useFrame } from '~/shared/utils';
import { useEvents } from '~/shared/hooks/useEvents';

export type M20CategoriesProps = M20CategoriesModule & {
    isSearchShortcuts?: boolean;
};

/**
 * List of category and/or page links, Can be used as display information without links.
 * The layout is supposed to be used with small illustrations or icons.
 */
export const M20Categories = memo(
    ({
        items,
        headline,
        subHeadline,
        subSubHeadline,
        callToAction,
        shownAs,
        priority,
        textColor,
        isSearchShortcuts,
        ...rest
    }: M20CategoriesProps) => {
        const leftArrow = useRef<HTMLButtonElement>(null);
        const rightArrow = useRef<HTMLButtonElement>(null);

        return (
            <>
                {!!items?.length && (
                    <ModuleContainer {...rest}>
                        <Gutter gutterSize="small">
                            <Flex alignItems="start">
                                {shownAs === 'Carousel' && (
                                    <StyledButton variant="icon" ref={leftArrow}>
                                        <ChevronLeft />
                                    </StyledButton>
                                )}
                                {!!headline?.text && (
                                    <FlexContent grow={1}>
                                        <StyledHeading>
                                            <ModuleHeader
                                                noGutter
                                                headline={headline}
                                                subHeadline={subHeadline}
                                                subSubHeadline={subSubHeadline}
                                                link={callToAction}
                                                textColor={textColor}
                                            />
                                        </StyledHeading>
                                    </FlexContent>
                                )}
                                {shownAs === 'Carousel' && (
                                    <StyledButton variant="icon" ref={rightArrow}>
                                        <ChevronRight />
                                    </StyledButton>
                                )}
                            </Flex>
                        </Gutter>
                        {!shownAs && !isSearchShortcuts && (
                            <CategoryList priority={priority} items={items} />
                        )}
                        {!shownAs && isSearchShortcuts && (
                            <CategoryShortcutList priority={priority} items={items} />
                        )}
                        {shownAs === 'Standard' && (
                            <CategoryListNarrow priority={priority} items={items} />
                        )}
                        {shownAs === 'Tiles' && <CategoryTiles priority={priority} items={items} />}
                        {shownAs === 'Carousel' && (
                            <CategoryCarousel
                                leftArrow={leftArrow}
                                rightArrow={rightArrow}
                                priority={priority}
                                items={items}
                            />
                        )}
                    </ModuleContainer>
                )}
            </>
        );
    },
);

type CategoryListProps = {
    items: Category[];
    priority?: boolean;
};

function CategoryShortcutList(props: CategoryListProps) {
    const { items, priority } = props;
    const { results } = useHits();

    return (
        <StyledCategories>
            {items?.map((item) => {
                return (
                    <div key={weakKey(item)} tabIndex={0} aria-label={item.title}>
                        <Card item={item} hitsResults={results} priority={priority} />
                    </div>
                );
            })}
        </StyledCategories>
    );
}

function CategoryList(props: CategoryListProps) {
    const { items, priority } = props;

    return (
        <StyledCategories>
            {items?.map((item) => {
                return <Card key={weakKey(item)} item={item} priority={priority} />;
            })}
        </StyledCategories>
    );
}

function CategoryListNarrow(props: CategoryListProps) {
    const { items, priority } = props;

    return (
        <Grid
            flow="row"
            columnGap="none"
            rowGap="none"
            breakpoints={{
                [mq('xs')]: {
                    columns: '1fr',
                },
                [mq('md')]: {
                    columns: '1fr 10fr 1fr',
                    columnGap: 'large',
                    rowGap: 'large',
                },
            }}
        >
            <div />
            <StyledCategories>
                {items?.map((item) => {
                    return <Card key={weakKey(item)} item={item} priority={priority} />;
                })}
            </StyledCategories>
        </Grid>
    );
}

type CardProps = {
    item: Category;
    className?: string;
    priority?: boolean;
    largePadding?: boolean;
    noPadding?: boolean;
    dataid?: string;
    hitsResults?: algoliasearchHelper.SearchResults<BaseHit>;
};

const Card: FC<CardProps> = (props) => {
    const { item, className, priority, largePadding, noPadding, dataid, hitsResults } = props;

    const { target, url } = item.callToAction || {};
    const pathname = usePathname();
    const isOfferPage = pathname?.includes('tilbudsavis');
    const { data } = useFrame();
    const { overlaySearchClickEvent } = useEvents(data);
    const { getHostName } = useUtils();

    const handleClick = (item: Category) => {
        overlaySearchClickEvent({
            search_term: hitsResults?.query ?? '',
            number_of_results: hitsResults?.nbHits,
            search_url: window.location.href,
            destination_url: `${getHostName()}${item?.callToAction?.url}`,
            type_of_result: 'Other shortcuts',
        });
    };

    const textContent = (
        <StyledAlignContent
            alignment={item.horizontalAlignment}
            position={item.verticalAlignment}
            textColor={item.textColor}
        >
            {item.headline?.text ? (
                <Text
                    children={item.headline.text}
                    color={item.headline.textColor || item.textColor}
                    as={item.headline.tag}
                    variant={item.headline.style}
                />
            ) : null}
            {item.subHeadline?.text ? (
                <Text
                    children={item.subHeadline.text}
                    color={item.subHeadline.textColor || item.textColor}
                    as={item.subHeadline.tag}
                    variant={item.subHeadline.style}
                />
            ) : null}
            {item.subSubHeadline?.text ? (
                <Text
                    children={item.subSubHeadline.text}
                    color={item.subSubHeadline.textColor || item.textColor}
                    as={item.subSubHeadline.tag}
                    variant={item.subSubHeadline.style}
                />
            ) : null}{' '}
            {item.title ? (
                <StyledText variant="display4" color={item.textColor} children={item.title} />
            ) : null}
        </StyledAlignContent>
    );

    return (
        <StyledCategory
            className={className}
            data-testid={isOfferPage ? `offer-item-${dataid}` : null}
        >
            {item.image?.src && (
                <>
                    <Image
                        priority={priority}
                        {...item.image}
                        alt={item.imageAltText || item.image.name}
                        width={227}
                        height={192}
                        layout="responsive"
                        objectFit="cover"
                    />
                    {item.imageShadow && <Gradient></Gradient>}
                </>
            )}
            <StyledContent noPadding={noPadding} largePadding={largePadding}>
                {url ? (
                    <StyledLink
                        aria-label={item.headline?.text || 'Card Link'}
                        onClick={() => handleClick(item)}
                        href={url}
                        target={target}
                    >
                        {textContent}
                    </StyledLink>
                ) : (
                    textContent
                )}
            </StyledContent>
        </StyledCategory>
    );
};

type CarouselProps = {
    items: Category[];
    priority?: boolean;
    leftArrow?: RefObject<HTMLButtonElement>;
    rightArrow?: RefObject<HTMLButtonElement>;
};

const CategoryCarousel: FC<CarouselProps> = (props: CarouselProps) => {
    const { items, priority, leftArrow, rightArrow } = props;
    const itemsPerRow = 4;
    return (
        <Carousel
            options={{
                spaceBetween: 0,

                slidesPerView: itemsPerRow,
                grid: {
                    fill: 'row',
                    rows: Math.ceil(items.length / itemsPerRow),
                },

                breakpoints: {
                    [breakpointsRaw.md]: {
                        slidesPerView: 6,
                        grid: undefined,
                    },
                },
            }}
            prevRef={leftArrow}
            nextRef={rightArrow}
            items={items?.map((item) => (
                <CarouselCard
                    noPadding={true}
                    priority={priority}
                    key={weakKey(item)}
                    item={item}
                />
            ))}
        />
    );
};

const CarouselCard = styled(Card)<{ noPadding?: boolean }>(({ theme, noPadding }) => ({
    padding: noPadding ? 0 : theme.space[3],
    [mq(0, 'sm')]: {
        padding: noPadding ? 0 : theme.space[3],
    },
}));

const CategoryTiles: FC<CarouselProps> = (props) => {
    const { items, priority } = props;

    return (
        <StyledFlex column>
            {items?.map((item, index) => (
                <>
                    <FlexContent key={weakKey(item)} grow={1} basis={0}>
                        <Card
                            largePadding
                            item={item}
                            priority={priority}
                            dataid={index.toString()}
                        />
                    </FlexContent>
                </>
            ))}
        </StyledFlex>
    );
};
